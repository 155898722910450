/**/
.nav-link {
  color: #2c3e50;
}
.nav-link:hover {
  background-color: #eeeeee;
  color: #2c3e50;
}
.nav-link .active {
  background-color: rgba(0, 0, 255, 0.2);
}
.accordion-body {
  background: #ffffff;
  border: 1px solid black;
  margin: 0;
}

.accordion-button:not(.collapsed) {
  background: #ffffff;
}
.accordion-header:focus {
  z-index: 3;
  border-color: #ff0000;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
:focused {
  border-color: #ff0000;
}
input[type="file"] {
}
.fix-input {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 1px;
  padding: 6px 12px;
  cursor: pointer;
  border-color: #0d6efd;
  border-radius: 0.25rem;
}
.choice-btn {
  margin-left: 2rem;
  margin-right: 2rem;
}
